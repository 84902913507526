<template>
    <div class="page_container">
        <div class="page_head">
            <div>
                <div class="page_head-title">Campaigns</div>
                <p class="page_head-description">Lorem ipsum dolor sit amet consectetur. Risus varius at egestas pretium viverra placerat iaculis posuere</p>
            </div>
            <div>
                <b-button class="add_btn" @click="$router.push('/new_campaign')">+ Add new</b-button>
            </div>
        </div>
        <div class="page_search-content">
            <div class="input_item">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#333333" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <b-form-input
                        v-model="search_term"
                        placeholder="Search"
                >
                </b-form-input>
            </div>
            <div class="input_item">
                <b-dropdown size="lg" variant="link"  toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="filter_dropdown"><div class="filter_icon"></div>Filter by status</div>
                    </template>
                    <b-dropdown-item @click="active_status = ''">All</b-dropdown-item>
                    <b-dropdown-item @click="active_status = 'Error'">Error</b-dropdown-item>
                    <b-dropdown-item @click="active_status = 'Active'">Active</b-dropdown-item>
                    <b-dropdown-item @click="active_status = 'Completed'">Completed</b-dropdown-item>
                    <b-dropdown-item @click="active_status = 'Paused'">Paused</b-dropdown-item>
                    <b-dropdown-item @click="active_status = 'Draft'">Draft</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div class="page_campaigns">
            <div v-for="item of filteredItems" class="campaign_item">
                <div class="campaign_item-data">
                    <div class="campaign_item-avatar"
                         :style="{ backgroundImage: 'url(' + item.instagram_account.media_url + ')' }">
                        <div class="instagram_logo"></div>
                    </div>
                    <div class="campaign_item-box">
                        <div class="campaign_item-name">{{item.name}}</div>
                        <div class="campaign_item-date">{{getDate(item.created_at)}}</div>
                    </div>

                </div>
                <div class="campaign_item-status">
                    <div class="campaign_item-status-btn"
                              :class="{
                        'error': item.status == 'Error',
                        'active': item.status == 'Active',
                        'completed': item.status == 'Completed',
                        'paused': item.status == 'Paused',
                        'draft': item.status == 'Draft'
                    }"
                    >{{item.status}}</div>
                </div>
                <div class="campaign_item-actions">
                    <div class="campaign_item-action">
                        <div class="action_replay"></div>
                        <div class="action_value">{{item.replays || 0}}</div>
                    </div>
                    <div class="campaign_item-action">
                        <div class="action_message"></div>
                        <div class="action_value">{{item.messages || 0}}</div>
                    </div>
                    <div class="campaign_item-action">
                        <div class="action_direct"></div>
                        <div class="action_value">{{item.direct || 0}}</div>
                    </div>
                    <div class="campaign_item-action more">
                        <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <div class="action_more"></div>
                            </template>
                            <b-dropdown-item href="#">Edit</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="#">Delete</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item href="#">Copy</b-dropdown-item>
                        </b-dropdown>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Campaign",
        data() {
            return {
                search_term: '',
                active_status: '',
                campaigns: [
                    // {
                    //     name: 'Name Surname test test',
                    //     avatar: null,
                    //     status: 'Error',
                    //     date: '11 min ago',
                    //     replays: 0,
                    //     messages: 0,
                    //     direct: 1
                    // },
                    // {
                    //     name: 'Jack Smith',
                    //     avatar: null,
                    //     status: 'Active',
                    //     date: '11 min ago',
                    //     replays: 1,
                    //     messages: 0,
                    //     direct: 1
                    // },
                    // {
                    //     name: 'Naomy test test goooo',
                    //     avatar: null,
                    //     status: 'Completed',
                    //     date: '11 min ago',
                    //     replays: 1,
                    //     messages: 0,
                    //     direct: 1
                    // },
                    // {
                    //     name: 'Win Disel',
                    //     avatar: null,
                    //     status: 'Paused',
                    //     date: '11 min ago',
                    //     replays: 1,
                    //     messages: 0,
                    //     direct: 1
                    // },
                    // {
                    //     name: 'John Doe',
                    //     avatar: null,
                    //     status: 'Draft',
                    //     date: '11 min ago',
                    //     replays: 1,
                    //     messages: 0,
                    //     direct: 1
                    // }
                ]
            }
        },
        computed: {
            filteredItems() {
                let filteredItems = this.campaigns.filter((campaign) => {
                    return campaign.name.toLowerCase().includes(this.search_term.toLowerCase());
                })
                let chosenStatus = filteredItems.filter((item) => {
                    if (this.active_status) {
                        return item.status == this.active_status
                    } else {
                        return item
                    }
                })
                return chosenStatus
            }
        },
        created() {
            this.getCampaigns()
            .then(res => {
                this.campaigns = res.data
            })
        },
        methods: {
            ...mapActions(['getCampaigns']),
            getDate(item) {
                let d = new Date(item).toLocaleString("en-US", {timeZoneName: "short"}).split(',')[0]
                return d
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        padding: 8px 0;
    }
    &_head {
        padding-right: 100px;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            padding-right: 30px;
        }
        & .add_btn {
            width: 130px;
            height: 50px;
            background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
            border-radius: 10px;
            border: none;
            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
            &:focus {
                background: #8103F6;
            }
        }
        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #000;
            margin-bottom: 8px;
        }
        &-description {
            max-width: 456px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #393939;
        }
    }
    &_search {
        &-content {
            display: flex;
            & .input_item {
                position: relative;
                width: 320px;
                margin-right: 16px;
                & svg {
                    position: absolute;
                    top: 14px;
                    left: 16px;
                }
                & input {
                    padding-left: 45px;
                    height: 44px;
                    outline: none;
                    line-height: 24px;
                    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
                    &::placeholder {
                        color: #393939;
                        line-height: 24px;
                    }
                }
                & .b-dropdown {
                    width: 160px;
                    height: 44px;
                    background: #FFFFFF;
                    border: 1px solid #E7E7E7;
                    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }
                }
                ::v-deep .btn {
                    &:focus,
                    &:focus-visible {
                        box-shadow: none !important;
                        outline: none !important;
                    }
                }
                & .filter {
                    &_dropdown {
                        display: flex;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                    }
                    &_icon {
                        width: 20px;
                        height: 20px;
                        background: url("~@/assets/images/filters_lines.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &_campaigns {
        padding: 30px 0;
        padding-right: 100px;
        @media screen and (max-width: 1024px) {
            padding-right: 30px;
        }
        & .campaign_item {
            width: 100%;
            height: 96px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            padding: 20px;
            &-avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: url("~@/assets/images/user_empty_img.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                & .instagram_logo {
                    position: absolute;
                    bottom: 0;
                    right: -6px;
                    width: 24px;
                    height: 24px;
                    background: url("~@/assets/images/instagram_icon.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            &-data {
                display: flex;
                align-items: center;
                max-width: 360px;
                width: 100%;
            }
            &-box {
                display: flex;
                flex-direction: column;
                padding: 0 20px;
                justify-content: space-between;
            }
            &-name {
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                padding-bottom: 10px;
            }
            &-date {
                font-size: 14px;
                line-height: 20px;
                color: #393939;
            }
            &-status {
                &-btn {
                    width: 110px;
                    height: 40px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    background: #ffffff;
                    border: none;
                    color: grey;
                    &:hover {
                        /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
                    }
                    &:focus {

                    }
                    &.error {
                        background: #FFA6A6;
                        color: #FF0000;
                    }
                    &.active {
                        background: #E9F4FF;
                        color: #1890FF;
                    }
                    &.completed {
                        background: #D7FFE4;
                        color: #009031;
                    }
                    &.paused {
                        background: #FFE8CC;
                        color: #FA7321;
                    }
                    &.draft {
                        background: #FFF2F8;
                        color: #7B6CCF;
                    }
                }
            }
            &-actions {
                display: flex;
                align-items: center;
            }
            &-action {
                width: 60px;
                height: 44px;
                border: 1px solid #E7E7E7;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin: 0 8px;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                &.more {
                    border: none;
                    cursor: pointer;
                    margin: 0;
                   ::v-deep ul {
                        border-radius: 0 !important;
                       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                       border: none;
                    }
                }
                & .action {
                    &_replay {
                        width: 16px;
                        height: 13px;
                        background: url("~@/assets/images/replay.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &_message {
                        width: 17px;
                        height: 16px;
                        background: url("~@/assets/images/message.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &_direct {
                        width: 15px;
                        height: 15px;
                        background: url("~@/assets/images/direct_action.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    &_more {
                        width: 20px;
                        height: 4px;
                        background: url("~@/assets/images/dots.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
</style>
